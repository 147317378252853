import React, { useState } from 'react'
import styled from 'styled-components'
import '../../assets/styles/component.css'
import { data } from '../../data.js'

const Heading = styled.h1 `
    margin-top: 0;
`

const ModalImages = styled.img `
    max-width: 200px;
`

const ModalP = styled.p `
    text-align: center;
`

const Nineteen = () => {

    const [ plant, setPlant ] = useState()
    return (
        <div>
            { plant === '44' &&
                <div className='area_modal'>
                    <h2>{data[44].botanic_name}, {data[44].maori_name}</h2>
                    <ModalImages src={data[44].image_url} alt='plant images' />
                    <ModalP>{data[44].habitat}</ModalP>
                    <a href={data[44].url} target='blank'>Click here to NZPCN page</a>
                    <button class="close-button" onClick={() => setPlant()}>X</button>
                </div>
            }
            { plant === '43' &&
                <div className='area_modal'>
                    <h2>{data[43].botanic_name}, {data[43].maori_name}</h2>
                    <ModalImages src={data[43].image_url} alt='plant images' />
                    <ModalP>{data[43].habitat}</ModalP>
                    <a href={data[43].url} target='blank'>Click here to NZPCN page</a>
                    <button class="close-button" onClick={() => setPlant()}>X</button>
                </div>
            }
            { plant === '11' &&
                <div className='area_modal'>
                    <h2>{data[11].botanic_name}, {data[11].maori_name}</h2>
                    <ModalImages src={data[11].image_url} alt='plant images' />
                    <ModalP>{data[11].habitat}</ModalP>
                    <a href={data[11].url} target='blank'>Click here to NZPCN page</a>
                    <button class="close-button" onClick={() => setPlant()}>X</button>
                </div>
            }
            { plant === '44' &&
                <div className='area_modal'>
                    <h2>{data[44].botanic_name}, {data[44].maori_name}</h2>
                    <ModalImages src={data[44].image_url} alt='plant images' />
                    <ModalP>{data[44].habitat}</ModalP>
                    <a href={data[44].url} target='blank'>Click here to NZPCN page</a>
                    <button class="close-button" onClick={() => setPlant()}>X</button>
                </div>
            }
            { plant === '40' &&
                <div className='area_modal'>
                    <h2>{data[40].botanic_name}, {data[40].maori_name}</h2>
                    <ModalImages src={data[40].image_url} alt='plant images' />
                    <ModalP>{data[40].habitat}</ModalP>
                    <a href={data[40].url} target='blank'>Click here to NZPCN page</a>
                    <button class="close-button" onClick={() => setPlant()}>X</button>
                </div>
            }
            { plant === '28' &&
                <div className='area_modal'>
                    <h2>{data[28].botanic_name}, {data[28].maori_name}</h2>
                    <ModalImages src={data[28].image_url} alt='plant images' />
                    <ModalP>{data[28].habitat}</ModalP>
                    <a href={data[28].url} target='blank'>Click here to NZPCN page</a>
                    <button class="close-button" onClick={() => setPlant()}>X</button>
                </div>
            }
            { plant === '41' &&
                <div className='area_modal'>
                    <h2>{data[41].botanic_name}, {data[41].maori_name}</h2>
                    <ModalImages src={data[41].image_url} alt='plant images' />
                    <ModalP>{data[41].habitat}</ModalP>
                    <a href={data[41].url} target='blank'>Click here to NZPCN page</a>
                </div>
            }
            { plant === '34' &&
                <div className='area_modal'>
                    <h2>{data[34].botanic_name}, {data[34].maori_name}</h2>
                    <ModalImages src={data[34].image_url} alt='plant images' />
                    <ModalP>{data[34].habitat}</ModalP>
                    <a href={data[34].url} target='blank'>Click here to NZPCN page</a>
                    <button class="close-button" onClick={() => setPlant()}>X</button>
                </div>
            }
            <div>
                <Heading>Section 19</Heading> 
                <p>KCC to Ken's seat </p>
                <h2>Planting Plan</h2>
                <p>Continue to plant directly behind the established vegetation, shrub species, and moving towards the top include low lying shrubs that will be favoured by lizards</p>
                <p>
                    <button className='button' type='button' onClick={() => setPlant('44')}>
                    Coprosma foetidissima
                    </button>
                    and
                    <button className='button' type='button' onClick={() => setPlant('43')}>
                    rhamnoides,
                    </button>
                    <button className='button' type='button' onClick={() => setPlant('11')}>
                    Plagianthus regius,
                    </button>
                    <button className='button' type='button' onClick={() => setPlant('44')}>
                    Leptecophylla juniperina,
                    </button>
                    <button className='button' type='button' onClick={() => setPlant('40')}>
                    muehlenbeckia,
                    </button>
                    <button className='button' type='button' onClick={() => setPlant('28')}>
                    Melicope simplex,
                    </button>
                    <button className='button' type='button' onClick={() => setPlant('41')}>
                    Melicytus alpinus.
                    </button>
                    </p>
                    <p>
                    Planting
                    <button className='button' type='button' onClick={() => setPlant('34')}>
                    Phormium tenax
                    </button>
                    on the lower slope where Bracken dominates.
                     </p>
            </div>
        </div>
    )
}

export default Nineteen
