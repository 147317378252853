import React from 'react'
import styled from 'styled-components'

const Heading = styled.h1 `
    margin-top: 0;
`

const ThreeC = () => {
    return (
      <div>
          <Heading>Section Three C</Heading>
          <p>This is a new section addition to the planting plan and the profile is still in development.</p>
      </div>
    )
}

export default ThreeC
