export const data = [
 {
   "id": 1,
   "botanic_name": "Aristotelia serrata",
   "maori_name": "Makamako",
   "common_name": "Wineberry",
   "habitat": "Lowland to montane forests. Often forming dense thickets following disturbance.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/34/492/aristotelia_serrata-07.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/aristotelia-serrata/"
 },
 {
   "id": 2,
   "botanic_name": "Carpodetus serratus",
   "maori_name": "Putaputaweta",
   "common_name": "Marbleleaf",
   "habitat": "Coastal to montane (10-1000 m a.s.l.). Moist broadleaf forest, locally common in beech forest. A frequent component of secondary forest. Streamsides and forest margins.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/35/181/carpodetus-serratus-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/carpodetus-serratus/"
 },
 {
   "id": 3,
   "botanic_name": "Dacrydium cupressinum",
   "maori_name": "Rimu",
   "common_name": "Red Pine",
   "habitat": "Lowland to montane forest - occasionally ascending to subalpine scrub.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/38/818/rimu-fruit.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/dacrydium-cupressinum/"
 },
 {
   "id": 4,
   "botanic_name": "Dracophyllum longifolium",
   "maori_name": "Inaka",
   "common_name": "",
   "habitat": "Widespread from sea level to the alpine zone growing in forest, woodland, shrubland and bogs on mountain and hill slopes, oncoastal cliffs and on bluffs.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/853/dracophyllum-longifolium-mt.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/dracophyllum-longifolium-var-longifolium/"
 },
 {
   "id": 5,
   "botanic_name": "Fuchsia excorticata",
   "maori_name": "Kotukutuku",
   "common_name": "Tree Fuchsia",
   "habitat": "Common in lowland and lower mountainous forest areas, especially on the forest margins, in clearings, and by streams.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/37/371/fuchsia-excorticata-05.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/fuchsia-excorticata/"
 },
 {
   "id": 6,
   "botanic_name": "Hoheria angustifolia",
   "maori_name": "Houhere",
   "common_name": "Narrow-leaved Houhere",
   "habitat": "A common mostly lowland forest species frequenting alluvial forest where it may at times be dominant. Hoheria angustifolia is often an important host for taapia (Tupeia antarctica).",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/33/412/hoheria-angustifolia-dunedi.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/hoheria-angustifolia/"
 },
 {
   "id": 7,
   "botanic_name": "Olearia arborescens",
   "maori_name": "Pekapeka",
   "common_name": "Common tree daisy",
   "habitat": "Occurs in forest and shrublands in lowland and montane environments.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/30/606/olearia-arborescens.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/olearia-arborescens/"
 },
 {
   "id": 8,
   "botanic_name": "Olearia bullata",
   "maori_name": "Akeake",
   "common_name": "Occupies basin and valley floor alluvium, alluvial terraces, fans and flats and the steep gully sides and rock bluffs of surrounding hill slopes.",
   "habitat": "",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/30/631/olearia-bullata-in-flower-2.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/olearia-bullata/"
 },
 {
   "id": 9,
   "botanic_name": "Olearia hectorii",
   "maori_name": "Akeake",
   "common_name": "Deciduous tree daisy",
   "habitat": "Lowland to subalpine often at the base of steep hills on colluvium, or on alluvium in situations affected by flooding, debris avalanching, water-logging, drought and/or frost.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/23/609/olehec-02.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/olearia-hectorii/"
 },
 {
   "id": 10,
   "botanic_name": "Pittosporum eugenioides",
   "maori_name": "Tarata",
   "common_name": "Lemonwood",
   "habitat": "Common tree of regenerating and mature forest in coastal to montane situations.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/31/421/pittosporum-eugenioides-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/pittosporum-eugenioides/"
 },
 {
   "id": 11,
   "botanic_name": "Plagianthus regius",
   "maori_name": "Manatu",
   "common_name": "Ribbonwood",
   "habitat": "Coastal to lower montane. Often a prominent tree in lowland alluvial forest.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/31/492/plagianthus-regius.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/plagianthus-regius-subsp-regius/"
 },
 {
   "id": 12,
   "botanic_name": "Prumnopitys taxifolia",
   "maori_name": "Matai",
   "common_name": "Black Pine",
   "habitat": "Lowland forest. Often in drier climates, where it can dominate alluvial soils which are waterlogged/flooded in winter and dry in summer. Seems to prefer base-rich substrates and soils.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/31/881/prumnopitys-taxifolia-04.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/prumnopitys-taxifolia/"
 },
 {
   "id": 13,
   "botanic_name": "Pseudopanax crassifolius",
   "maori_name": "Horoeka",
   "common_name": "Lancewood",
   "habitat": "Found as a sparse component of seasonally drought-prone but otherwise cold and wet alluvial forests. This species prefers drier habitats.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/25/606/psefer-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/pseudopanax-ferox/"
 },
 {
   "id": 14,
   "botanic_name": "Schefflera digitata",
   "maori_name": "Pate",
   "common_name": "Seven Finger",
   "habitat": "Lowland to montane forest.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/32/605/schefflera-digitata-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/schefflera-digitata/"
 },
 {
   "id": 15,
   "botanic_name": "Cortaderia richardii",
   "maori_name": "Toetoe",
   "common_name": "",
   "habitat": "Abundant, from the coast to subalpine areas. Common along stream banks, river beds, around lake margins, and in other wet places. Also found in sand dunes, especially along the Foveaux Strait.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/469/nzp-0992.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/austroderia-richardii/"
 },
 {
   "id": 16,
   "botanic_name": "Dicksonia squarrosa",
   "maori_name": "Wheki",
   "common_name": "Rough tree fern",
   "habitat": "It is particularly abundant around streams and other wet areas. ",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/643/dicksonia-squarrosa-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/dicksonia-squarrosa/"
 },
 {
   "id": 17,
   "botanic_name": "Poa astonii",
   "maori_name": "Pātītī",
   "common_name": "Blue tussock",
   "habitat": " It is a coastal species so grows well in exposed conditions with heavy winds and salt spray. It also thrives in low alpine sites. Easy to grow and hardy.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/31/573/poa-cooks-otago.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/poa-astonii/"
 },
 {
   "id": 18,
   "botanic_name": "Carex trifida",
   "maori_name": "Tataki",
   "common_name": "Mutton-bird Sedge",
   "habitat": "Strictly coastal where it often associated with seal haul outs and sea bird nesting grounds, especially - as the common name suggests - muttonbirds (Puffinus spp.). Usually in open sites, very rarely found in canopy gaps within coastal forest.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/33/838/carex-trifida--catlins.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/carex-trifida/"
 },
 {
   "id": 19,
   "botanic_name": "Carex appressa",
   "maori_name": "Pūrei",
   "common_name": "",
   "habitat": " Mostly coastal, extending to montane in the subantarctic islands. Preferring open situations, usually on the margins of peat bogs or peaty, slow-flowing streams.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/38/177/carex-appressa.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/carex-appressa/"
 },
 {
   "id": 20,
   "botanic_name": "Poa cita",
   "maori_name": "Pātītī",
   "common_name": "Silver tussock",
   "habitat": "Likes a dry sunny spot and looks best when grown in poorer soil.",
   "area_id": "",
   "image_url": "https://www.tawapou.co.nz/media/images/_400x400_crop_center-center_95/2015-11-20-15.21.56.jpg",
   "url": "https://www.kauriparknurseries.co.nz/plants/poa-cita-silver-tussock/"
 },
 {
   "id": 21,
   "botanic_name": "Carex testacea",
   "maori_name": "Mānaia",
   "common_name": "Carex testacea",
   "habitat": "Coastal to montane. In sand dunes, coastal forest and scrub, dense forest or short tussock (Festuca novae-zelandiae (Hack.) Cockayne) grassland.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/33/825/carex-testacea-1.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/carex-testacea/"
 },
 {
   "id": 22,
   "botanic_name": "Melicytus ramiflorus",
   "maori_name": "Mahoe",
   "common_name": "Whitey wood",
   "habitat": "Abundant small tree of coastal, lowland, and lower montane forests throughout the country.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/29/886/melicytus-ramiflorus-02.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/melicytus-ramiflorus/"
 },
 {
   "id": 23,
   "botanic_name": "Olearia fragrantissima",
   "maori_name": "Akeake",
   "common_name": "Fragrant tree daisy",
   "habitat": "usually in grey scrub, on forest margins or shrublands. Sometimes on the margins of estuarine or saltmarsh vegetation in places which would be subject to saline water in extremes of tide, also found on gravelly soils often on the margins of steep gullies, gorges and in boulder field.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/25/441/olearia-fragrantissima-2-co.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/olearia-fragrantissima/"
 },
 {
   "id": 24,
   "botanic_name": "Olearia avicenniifolia",
   "maori_name": "Akeake",
   "common_name": "",
   "habitat": "Sub-alpine scrub to 1000 metres in South Island.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/30/624/olearia-avicennifolia-near-.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/olearia-avicenniifolia/"
 },
 {
   "id": 25,
   "botanic_name": "Kunzea ericoides",
   "maori_name": "Kanuka",
   "common_name": "",
   "habitat": "Coastal to lowland shrubland, regenerating forest and forest margins, also present in montane forest, ultramafic shrubland and very occasionally present in subalpine shrubland.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/29/253/kunzea-ericoides-s.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/kunzea-ericoides/"
 },
 {
   "id": 26,
   "botanic_name": "Veronica elliptica",
   "maori_name": "Kokomuka",
   "common_name": "Shore hebe",
   "habitat": "Grows in coastal areas, often in exposed places on rocks",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/39/157/hebeb-elliptica--snares.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/veronica-elliptica/"
 },
 {
   "id": 27,
   "botanic_name": "Ozothamnus leptophyllus",
   "maori_name": "Tauhinu",
   "common_name": "",
   "habitat": "Present only in areas of open scrub or thicket, never in dense closed scrub",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/30/944/ozothamnus-leptophyllus-02.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/ozothamnus-leptophyllus/"
 },
 {
   "id": 28,
   "botanic_name": "Helichrysum lanceolatum",
   "maori_name": "Niniao",
   "common_name": "",
   "habitat": "Will love being in dry forest edge and scrub habitats.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/28/796/helichrysum-lanceolatum-02a.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/helichrysum-lanceolatum/"
 },
 {
   "id": 29,
   "botanic_name": "Melicope simplex",
   "maori_name": "Poataniwha",
   "common_name": "",
   "habitat": "Lizard food!",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/29/817/melicope-simplex-1.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/melicope-simplex/"
 },
 {
   "id": 30,
   "botanic_name": "Coprosma propinqua",
   "maori_name": "Mingimingi",
   "common_name": "",
   "habitat": "Lizard food!",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/068/coprosma-propinqua-06b.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/coprosma-propinqua-var-propinqua/"
 },
 {
   "id": 31,
   "botanic_name": "Sophora prostrata",
   "maori_name": "Kowhai",
   "common_name": "",
   "habitat": "Happy in a diverse range of habitats from riparian forests, coastal cliff faces to inland grey scrub communities.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/32/748/nzp-0927.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/sophora-prostrata/"
 },
 {
   "id": 32,
   "botanic_name": "Cordyline australis",
   "maori_name": "Ti kouka",
   "common_name": "Cabbage tree",
   "habitat": "Widespread and common from coastal to montane forest. Most commonly encountered on alluvial terraces within riparian forest.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/260/cabbage-tree-03.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/cordyline-australis/"
 },
 {
   "id": 33,
   "botanic_name": "Pectinopitys ferruginea",
   "maori_name": "Miro",
   "common_name": "Brown Pine",
   "habitat": "Common tree of lowland to montane forest.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/28/663/stachypitis-ferrugineus-1.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/pectinopitys-ferruginea/"
 },
 {
   "id": 34,
   "botanic_name": "Podocarpus totara",
   "maori_name": "Totora",
   "common_name": "",
   "habitat": "Widespread and at times abundant tree of lowland, montane and lower subalpine forest. May also form a vegetation type in which it is the dominant species.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/31/719/totara-foliage.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/podocarpus-totara-var-totara/"
 },
 {
   "id": 35,
   "botanic_name": "Phormium tenax",
   "maori_name": "Harakeke",
   "common_name": "Flax",
   "habitat": "Common from lowland and coastal areas to montane forest, usually but not exclusively, in wetlands and in open ground along riversides.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/39/708/phormium-tenax-1.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/phormium-tenax/"
 },
 {
   "id": 36,
   "botanic_name": "Disphyma australe subsp. stricticaule",
   "maori_name": "Horokaka",
   "common_name": "Kermadec Ice plant",
   "habitat": "Coastal. On rock stacks, cliff faces, banks, cobble and boulder beaches, or in the vicinity of bird nesting grounds. Widespread and common.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/26/583/nzpc-006.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/disphyma-australe-subsp-stricticaule/"
 },
 {
   "id": 37,
   "botanic_name": "Coprosma areolata",
   "maori_name": "Karamū",
   "common_name": "Thin-leaved Coprosma",
   "habitat": "Damp, lowland forest. Will also grow in exposed conditions.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/35/839/coprosma-areolata-1.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/coprosma-areolata/"
 },
 {
   "id": 38,
   "botanic_name": "Corokia cotoneaster",
   "maori_name": "Korokio",
   "common_name": "Wire-netting bush",
   "habitat": "Prefers to survive in areas that are not wet. This plant likes to grow and develop in dry, rocky, and dense soils.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/420/corokia_cotoneaster.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/corokia-cotoneaster/"
 },
 {
   "id": 39,
   "botanic_name": "Carmichaelia",
   "maori_name": "Makaka",
   "common_name": "Broom",
   "habitat": "Coastal to montane, on river terraces, stream banks, colluvium, rock outcrops, talus and fan toe slopes, among tussock grassland and grey scrub, on the edge and margins of dense bush, forest, and in swamps",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/35/104/carmichaelia-australis-2.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/carmichaelia-australis/"
 },
 {
   "id": 40,
   "botanic_name": "Brachyglottis greyi",
   "maori_name": "Rangiora",
   "common_name": "",
   "habitat": "Primarily a coastal species of rock outcrops and bluffs but may extend inland up river gorges and in suitably exposed bluff habitats",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/34/861/brachyglottis-greyi-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/brachyglottis-greyi/"
 },
 {
   "id": 41,
   "botanic_name": "Muehlenbeckia australis",
   "maori_name": "Pohuehue",
   "common_name": "",
   "habitat": "Lizard food!",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/30/119/muehlenbeckia-australis-2.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/muehlenbeckia-australis/"
 },
 {
   "id": 42,
   "botanic_name": "Melicytus alpinus",
   "maori_name": "Hinahina",
   "common_name": "Porcupine shrub",
   "habitat": "Lizard food!",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/29/847/melicytus-alpinus-02.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/melicytus-alpinus/"
 },
 {
   "id": 43,
   "botanic_name": "Coprosma rhamnoides",
   "maori_name": "Mingimingi",
   "common_name": "",
   "habitat": "Lizard food!",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/100/coprosma-rhamnoides-02.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/coprosma-rhamnoides/"
 },
 {
   "id": 44,
   "botanic_name": "Coprosma foetidissima",
   "maori_name": "Hūpiro",
   "common_name": "Stinkwood, shit shrub",
   "habitat": "",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/35/953/coprosma-foetidissima-2.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/coprosma-foetidissima/"
 },
 {
   "id": 45,
   "botanic_name": "Leptecophylla juniperina",
   "maori_name": "Prickly Mingimingi",
   "common_name": "",
   "habitat": "",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/29/403/cyathodes-juniperina-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/leptecophylla-juniperina-subsp-juniperina/"
 },
 {
   "id": 46,
   "botanic_name": "Griselinia littoralis",
   "maori_name": "Kapuka",
   "common_name": "Broadleaf",
   "habitat": "",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/37/789/griselinea-littoralis-male.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/griselinia-littoralis/"
 },
 {
   "id": 47,
   "botanic_name": "Veronica salicifolia",
   "maori_name": "Koromiko",
   "common_name": "",
   "habitat": "",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/39/185/hebe-salicifolia-pgj-01.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/veronica-salicifolia/"
 },
 {
   "id": 48,
   "botanic_name": "Myoporum laetum",
   "maori_name": "Ngaio",
   "common_name": "",
   "habitat": "Coastal to lowland forest, sometimes well inland (in Hawkes Bay, Rangataiki and Wairarapa). Often uncommon over large parts of its range.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/30/154/nzp-0795.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/myoporum-laetum/"
 },
 {
   "id": 49,
   "botanic_name": "Coprosma rigida",
   "maori_name": "Karamū",
   "common_name": "",
   "habitat": "Coastal to lowland forest, sometimes well inland (in Hawkes Bay, Rangataiki and Wairarapa). Often uncommon over large parts of its range.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/113/coprosma-rigida-2.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/coprosma-rigida/"
 },
 {
   "id": 50,
   "botanic_name": "Pittosporum tenuifolium",
   "maori_name": "Kohukohu",
   "common_name": "Black matipo",
   "habitat": "A small tree of coastal to montane shrubland and forested habitats. Preferring successional habitats.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/31/461/pittosporum-tenuifolium-04.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/pittosporum-tenuifolium/"
 },
 {
   "id": 51,
   "botanic_name": "Carex flagellifera",
   "maori_name": "Mānaia",
   "common_name": "Glen Murray tussock",
   "habitat": "Coastal to montane. Usually in free draining soils under scrub or open forest. Rarely in wetlands or in permanently damp, shaded sites.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/33/688/carex-flagellifera-02b.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/carex-flagellifera/"
 }
]
