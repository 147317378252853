import React, { useState } from 'react'
import styled from 'styled-components'
import '../../assets/styles/component.css'
import { data } from '../../data.js'

const Heading = styled.h1 `
    margin-top: 0;
`

const ModalImages = styled.img `
    max-width: 200px;
`

const ModalP = styled.p `
    text-align: center;
`

const Thirty = () => {
    const [ plant, setPlant ] = useState()
    return (
        <div>
        { plant === '25' &&
            <div className='area_modal'>
                <h2>{data[25].botanic_name}, {data[25].maori_name}</h2>
                <ModalImages src={data[25].image_url} alt='plant images' />
                <ModalP>{data[25].habitat}</ModalP>
                <a href={data[25].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '47' &&
            <div className='area_modal'>
                <h2>{data[47].botanic_name}, {data[47].maori_name}</h2>
                <ModalImages src={data[47].image_url} alt='plant images' />
                <ModalP>{data[47].habitat}</ModalP>
                <a href={data[47].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '21' &&
            <div className='area_modal'>
                <h2>{data[21].botanic_name}, {data[21].maori_name}</h2>
                <ModalImages src={data[21].image_url} alt='plant images' />
                <ModalP>{data[21].habitat}</ModalP>
                <a href={data[21].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '40' &&
            <div className='area_modal'>
                <h2>{data[40].botanic_name}, {data[40].maori_name}</h2>
                <ModalImages src={data[40].image_url} alt='plant images' />
                <ModalP>{data[40].habitat}</ModalP>
                <a href={data[40].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === 'coprosma_spp' &&
            <div className='area_modal'>
                <h2>{data[36].botanic_name}, {data[36].maori_name}</h2>
                <ModalImages src={data[36].image_url} alt='plant images' />
                <ModalP>{data[36].habitat}</ModalP>
                <a href={data[36].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[29].botanic_name}, {data[29].maori_name}</h2>
                <ModalImages src={data[29].image_url} alt='plant images' />
                <ModalP>{data[29].habitat}</ModalP>
                <a href={data[29].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[36].botanic_name}, {data[36].maori_name}</h2>
                <ModalImages src={data[36].image_url} alt='plant images' />
                <ModalP>{data[36].habitat}</ModalP>
                <a href={data[36].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        <div>
            <Heading>Section 30</Heading> 
            <p>From fallen tree around to chapel.</p>
            <h2>Planting Plan</h2>
            <p>
                <button className='button' type='button' onClick={() => setPlant('25')}>
                    Hebe elliptica
                </button>
                and
                <button className='button' type='button' onClick={() => setPlant('coprosma_spp')}>
                    Coprosma spp,
                </button>
                <button className='button' type='button' onClick={() => setPlant('47')}>
                    Ngaio
                </button>
                and
                <button className='button' type='button' onClick={() => setPlant('21')}>
                    Mahoe
                </button>
                working from behind established plants and moving out to open spaces - only where fenced from sheep.
            </p>
        </div>
    </div>
    )
}

export default Thirty
