import React, { useState } from 'react'
import styled from 'styled-components'
import PlantTable from './PlantList/PlantTable.js'
import WeedTable from './PlantList/WeedTable.js'

const Text = styled.p `
  text-align: center;
`

const PlantList = () => {
  const [ list, setList ] = useState('')

  return (
    <div className='home_image'>
      <div className='options'>
        <button onClick={()=> setList('endemic')}>Endemic</button>
        <button onClick={()=> setList('invasive')}>Invasive</button>
      </div>
        {list === '' && <Text>Select to view a list of endemic plants that are referred to in the Restoration Project, or a list of invasive plants that are under control on the island.</Text>}
        {list === 'endemic' && <PlantTable />}
        {list === 'invasive' && <WeedTable />}
    </div>
  )
}

export default PlantList
