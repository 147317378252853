import React from 'react'
import styled from 'styled-components'
import { weed } from '../../weed.js'

const Title = styled.h1 `
  padding: 0 20px 0 20px;
  color: #1f7a8c;
`

const Heading = styled.h2 `
  padding: 0 20px 0 20px;
`

const Subheading = styled.h2 `
  padding: 0 20px 0 20px;
`

const Text = styled.p `
  padding: 0 20px 0 20px;
`

const WeedTable = () => {
    return (
        <div className='table_layout'>
            {weed.map((weed, key) => {
                return (
                    <div className='table_card' key={key}>
                        <img className='table_img' src={weed.image_url} alt='plant' />
                            <div className='table_intro'>
                                <Title>{weed.common_name}</Title>
                                <Subheading><a href={weed.url} target='blank'>{weed.botanic_name}</a></Subheading>
                                <Heading>Problem with this plant</Heading>
                                <Text>{weed.problem}</Text>
                                <Heading>Removal Method</Heading>
                                <Text>{weed.removal_method}</Text>
                            </div>
                    </div>
                );
            })}
        </div>
    )
}


export default WeedTable
