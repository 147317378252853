import React, { useState } from 'react'
import styled from 'styled-components'
import '../../assets/styles/component.css'
import { data } from '../../data.js'

const Heading = styled.h1 `
    margin-top: 10px;
`

const ModalImages = styled.img `
    max-width: 200px;
`

const ModalP = styled.p `
    text-align: center;
`

const ThirtyOne = () => {
    const [ plant, setPlant ] = useState()
    return (
        <div>
        { plant === '25' &&
            <div className='area_modal'>
                <h2>{data[25].botanic_name}, {data[25].maori_name}</h2>
                <ModalImages src={data[25].image_url} alt='plant images' />
                <ModalP>{data[25].habitat}</ModalP>
                <a href={data[25].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '34' &&
            <div className='area_modal'>
                <h2>{data[34].botanic_name}, {data[34].maori_name}</h2>
                <ModalImages src={data[34].image_url} alt='plant images' />
                <ModalP>{data[34].habitat}</ModalP>
                <a href={data[34].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '35' &&
            <div className='area_modal'>
                <h2>{data[35].botanic_name}, {data[35].maori_name}</h2>
                <ModalImages src={data[35].image_url} alt='plant images' />
                <ModalP>{data[35].habitat}</ModalP>
                <a href={data[35].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '19' &&
            <div className='area_modal'>
                <h2>{data[19].botanic_name}, {data[19].maori_name}</h2>
                <ModalImages src={data[19].image_url} alt='plant images' />
                <ModalP>{data[19].habitat}</ModalP>
                <a href={data[19].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === 'coprosma_spp' &&
            <div className='area_modal'>
                <h2>{data[36].botanic_name}, {data[36].maori_name}</h2>
                <ModalImages src={data[36].image_url} alt='plant images' />
                <ModalP>{data[36].habitat}</ModalP>
                <a href={data[36].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[29].botanic_name}, {data[29].maori_name}</h2>
                <ModalImages src={data[29].image_url} alt='plant images' />
                <ModalP>{data[29].habitat}</ModalP>
                <a href={data[29].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[36].botanic_name}, {data[36].maori_name}</h2>
                <ModalImages src={data[36].image_url} alt='plant images' />
                <ModalP>{data[36].habitat}</ModalP>
                <a href={data[36].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        <div>
            <Heading>Section 31</Heading>
            <p>Chapel, jetty around to lodge.</p>
            <h2>Planting Plan</h2>
            <p>This side of the Island is, to an extent, sheltered from the prevailing winds and as such plant fecundity is lower.</p>
            <p>
                <button className='button' type='button' onClick={() => setPlant('25')}>
                    Hebe elliptica,
                </button>
                <button className='button' type='button' onClick={() => setPlant('coprosma_spp')}>
                    Coprosma spp,
                </button>
                and
                <button className='button' type='button' onClick={() => setPlant('34')}>
                    Phormium tenax
                </button>
                for bank stability.
                </p>
                <p>
                On coastal cliffs
                <button className='button' type='button' onClick={() => setPlant('35')}>
                Disphyma
                </button>
                and
                <button className='button' type='button' onClick={() => setPlant('19')}>
                    Poa cita
                </button>
            </p>
        </div>
    </div>
    )
}

export default ThirtyOne
