import React from 'react'
import styled from 'styled-components'
import { data } from '../../data.js'

const Heading = styled.h1 `
  padding: 0 20px 0 20px;
`

const Subheading = styled.h2 `
    padding: 0 20px 0 20px;
`

const Text = styled.p `
    padding: 0 20px 0 20px;
`

const PlantTable = () => {
    return (
        <div className='table_layout'>
            {data.map((data, key) => {
                return (
                    <div className='table_card' key={key}>
                        <img className='table_img' src={data.image_url} alt='plant' />
                            <div className='table_intro'>
                                <Heading><a href={data.url} target='blank'>{data.botanic_name}</a></Heading>
                                <Subheading>{data.maori_name}, {data.common_name}</Subheading>
                                <Text>{data.habitat}</Text>
                            </div>
                    </div>
                );
            })}
        </div>
    )
}


export default PlantTable
