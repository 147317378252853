export const weed = [
 {
   "id": 1,
   "botanic_name": "Berberis darwinii",
   "common_name": "Darwin’s barberry",
   "problem": "Long-lived plant, with well-dispersed seeds. Tolerates moderate to cold temperatures, damp to dry conditions, high wind, salt, shade, damage, grazing (not browsed), and a range of soils.",
   "removal_method": " Grub out (all year round). Leave on site to rot down or cut at base and apply triumph gel. Cut stumps resprout quickly, and can be hard to kill and seeds will germinate onto bare land. Follow up 6 monthly. Replant bare sites to minimise seeding.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1117/dscf0426.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/darwins-barberry/"
 },
 {
   "id": 2,
   "botanic_name": "Passiflora ‘Tacsonia’ subgroup",
   "common_name": "Banana Passionfruit",
   "problem": "Grows to medium-high canopy, where it forms large masses. Grows rapidly in most soil types, and produces highly viable seed in two years. Partly tolerant to shade, damage and drought, and stems root where they touch the ground.",
   "removal_method": "Pull roots up (all year round): Cut off above ground or tie stems in air to prevent them forming roots on contact with soil. Apply triumph gell to stump. Follow up regularly for new seedlings.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1293/dscf0101.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/banana-passionfruit/"
 },
 {
   "id": 3,
   "botanic_name": "Lycium ferocissimum",
   "common_name": "Boxthorn",
   "problem": "Overtops native plants and can become only woody plant species on site. Petrels and other seabirds can become entangled and die. Berries may poison birds.",
   "removal_method": "Hand pull seedlings, winch out larger plants (all year round): Plant material can be left on site. Stump must be treated or will grow back.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1205/dscf1006.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/boxthorn/"
 },
 {
   "id": 4,
   "botanic_name": "Hedera helix",
   "common_name": "Ivy",
   "problem": "Clings to and climbs almost any surface, can grow over forest floor, sub-canopy and canopy to great heights, forming dense, long-lived masses at a moderate to fast growth rate and completely smothering tree trunks and branches. Tolerates cold, damp, wind, salt, differing soil types, shade, damage, and drought.",
   "removal_method": "Pull roots up (all year round): Cut off above ground or tie stems in air to prevent them forming roots on contact with soil. Apply triumph gell to stump. Follow up regularly for new seedlings.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1176/dscf1003.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/ivy/"
 },
 {
   "id": 5,
   "botanic_name": "Dryopteris filix-mas",
   "common_name": "Male fern",
   "problem": "Male fern invades native habitats where it can out compete native ferns and other native species.",
   "removal_method": "Make sure it is identified correctly. Dig out entire rhizome and dispose off island. Return to area to check frequently.",
   "area_id": "",
   "image_url": "https://blog.tepapa.govt.nz/wp-content/uploads/2009/03/polypodium_microsorum_fronds21.jpg",
   "url": "http://www.apwt.org.nz/document/weeds/male_fern.pdf"
 },
 {
   "id": 6,
   "botanic_name": "Ulex europaeus",
   "common_name": "Gorse",
   "problem": "Forms pure associations temporarily in many habitats, inhibiting the establishment of native plant seedlings. Increased nitrogen in poor soil types changes native species able to grow there. Can be a nursery crop for native species, dying back when overtopped, but less likely on dry sites. ",
   "removal_method": "If possible, continue to cut back to release room for native seedlings. If removal is necessary, cut at stump and apply gel. Apply hummus/compost around endemic seedlings if the ground is very dry.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1269/ulex_europaeus_bp_ls.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/gorse/"
 },
 {
   "id": 7,
   "botanic_name": "Coprosma repens",
   "common_name": "Taupata",
   "problem": "Not endemic.",
   "removal_method": "Prune back to allow space for endemic seedlings. Only remove if necessary (ie new seedling). Cut at stump, remove root.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/36/094/coprosma-repens-09a.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/coprosma-repens/"
 },
 {
   "id": 8,
   "botanic_name": "Sambucus nigra",
   "common_name": "Elder",
   "problem": "Produces many well-dispersed seeds. Leaves are toxic so it is not grazed. It tolerates sun, shade and dry soils.",
   "removal_method": "Dig out seedlings and small plants. Mulch, all year round. Apply gel.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1252/2003_0311shed0042.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/elder/"
 },
 {
   "id": 9,
   "botanic_name": "Marrubium vulgare",
   "common_name": "Horehound  ",
   "problem": "Likes to grow in pool soils, with little other competition. Easily spreads.",
   "removal_method": "Use grubber to pull out entire plant and root. Bag and remove from the island. Make some tea with it to treat coughs and colds.",
   "area_id": "",
   "image_url": "http://agpest.co.nz/wp-content/uploads/2016/12/Horehound-3.jpg",
   "url": "https://www.landcareresearch.co.nz/discover-our-research/biosecurity/weed-management/approvals/horehound/"
 },
 {
   "id": 10,
   "botanic_name": "Vinca major",
   "common_name": "Periwinkle",
   "problem": "Smothers ground in open or shady conditions, prevents the seedlings of native species from establishing, and opening up habitats so that they are more vulnerable to weedy vines, grasses and so on.",
   "removal_method": "Very difficult. Require ongoing care and a plant succession plan to out compete over time. Remove entire plant, bag and take off the island.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1271/dscf6108.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/periwinkle/"
 },
 {
   "id": 11,
   "botanic_name": "Rosa rubiginosa",
   "common_name": "Briar",
   "problem": "Long-lived seed is occasionally well dispersed and spread is also by suckers. Tolerates drought, hot to very cold temperatures, wind, low fertility, most well-drained soils and damage (little grazed). Can dominate the canopy.",
   "removal_method": "Dig out (all year round): small patches only. Dispose of root crowns and rhizomes at a refuse transfer station or burn or bury deeply. Dab stump with gel if necessary.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1245/dscf1897.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/sweet-briar/"
 },
 {
   "id": 12,
   "botanic_name": "Rubus fruticosus agg.",
   "common_name": "Blackberry",
   "problem": "Forms dense, long-lived clumps, scrambles over the ground and low plants, has an extensive rhizome system, occasionally layering and seeding. Tolerates most soil types, drought and flood but is intolerant of dense shade.",
   "removal_method": "Dig out small patches (all year round): Dispose of root crowns and rhizomes at a refuse transfer station or burn or bury deeply. Apply gel onto stump",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1246/rubus_fruticosus_af.800x700-ms.jpg",
   "url": "https://www.weedbusters.org.nz/what-are-weeds/weed-list/blackberry/"
 },
 {
   "id": 13,
   "botanic_name": "Passiflora ‘Tacsonia’ subgroup",
   "common_name": "Banana Passionfruit",
   "problem": "Grows to medium-high canopy, where it forms large masses. Grows rapidly in most soil types, and produces highly viable seed in two years. Partly tolerant to shade, damage and drought, and stems root where they touch the ground.",
   "removal_method": "Cut off seeds, bag and remove. Rest of the plant can be left on site.",
   "area_id": "",
   "image_url": "https://www.oderings.co.nz/assets/Wallflower-Charity_400134_1.jpg",
   "url": ""
 },
 {
   "id": 14,
   "botanic_name": "Brachyglottis repanda",
   "common_name": "Rangiora",
   "problem": "Not endemic.",
   "removal_method": "Dig out whole plant and take care to carefully bag and dispose of leaves with contain spores. Follow up in effected areas frequently.",
   "area_id": "",
   "image_url": "https://www.nzpcn.org.nz/site/assets/files/0/34/893/nzp-1044.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/brachyglottis-repanda/"
 },
 {
   "id": 15,
   "botanic_name": "Lupinus arboreus",
   "common_name": "Tree lupin",
   "problem": "Grows and matures quickly, producing many long-lived seeds. Taller than competing coastal species, so can form a canopy. Tolerates wind, salt, hot to cold temperatures, damage and grazing (not readily eaten), drought, low fertility (fixes nitrogen), and fire but is intolerant of moderate shade and waterlogged soils.",
   "removal_method": "Cut off seeds if present, bag and remove. Cut at stump, gel if necessary. Leftovers can be left to rot down.",
   "area_id": "",
   "image_url": "https://www.weedbusters.org.nz/site/assets/files/1203/2006_0101weeds90035.800x700-ms.jpg",
   "url": "https://www.nzpcn.org.nz/flora/species/tree-lupin/"
 }
]
