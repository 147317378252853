import React, { useState } from 'react'
import styled from 'styled-components'
import '../../assets/styles/component.css'
import { data } from '../../data.js'


const Heading = styled.h1 `
    margin-top: 0;
`

const ModalImages = styled.img `
    max-width: 200px;
`

const ModalP = styled.p `
    text-align: center;
`

const Eleven = () => {
    const [ plant, setPlant ] = useState()

    return (
        <div>
        { plant === '25' &&
            <div className='area_modal'>
                <h2>{data[25].botanic_name}, {data[25].maori_name}</h2>
                <ModalImages src={data[25].image_url} alt='plant images' />
                <ModalP>{data[25].habitat}</ModalP>
                <a href={data[25].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '34' &&
            <div className='area_modal'>
                <h2>{data[34].botanic_name}, {data[34].maori_name}</h2>
                <ModalImages src={data[34].image_url} alt='plant images' />
                <ModalP>{data[34].habitat}</ModalP>
                <a href={data[34].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '47' &&
            <div className='area_modal'>
                <h2>{data[47].botanic_name}, {data[47].maori_name}</h2>
                <ModalImages src={data[47].image_url} alt='plant images' />
                <ModalP>{data[47].habitat}</ModalP>
                <a href={data[47].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '40' &&
            <div className='area_modal'>
                <h2>{data[40].botanic_name}, {data[40].maori_name}</h2>
                <ModalImages src={data[40].image_url} alt='plant images' />
                <ModalP>{data[40].habitat}</ModalP>
                <a href={data[40].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '49' &&
            <div className='area_modal'>
                <h2>{data[49].botanic_name}, {data[49].maori_name}</h2>
                <ModalImages src={data[49].image_url} alt='plant images' />
                <ModalP>{data[49].habitat}</ModalP>
                <a href={data[49].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '31' &&
            <div className='area_modal'>
                <h2>{data[31].botanic_name}, {data[31].maori_name}</h2>
                <ModalImages src={data[31].image_url} alt='plant images' />
                <ModalP>{data[31].habitat}</ModalP>
                <a href={data[31].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '21' &&
            <div className='area_modal'>
                <h2>{data[21].botanic_name}, {data[21].maori_name}</h2>
                <ModalImages src={data[21].image_url} alt='plant images' />
                <ModalP>{data[21].habitat}</ModalP>
                <a href={data[21].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '29' &&
            <div className='area_modal'>
                <h2>{data[29].botanic_name}, {data[29].maori_name}</h2>
                <ModalImages src={data[29].image_url} alt='plant images' />
                <ModalP>{data[29].habitat}</ModalP>
                <a href={data[29].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '48' &&
            <div className='area_modal'>
                <h2>{data[48].botanic_name}, {data[48].maori_name}</h2>
                <ModalImages src={data[48].image_url} alt='plant images' />
                <ModalP>{data[48].habitat}</ModalP>
                <a href={data[48].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '23' &&
            <div className='area_modal'>
                <h2>{data[23].botanic_name}, {data[23].maori_name}</h2>
                <ModalImages src={data[23].image_url} alt='plant images' />
                <ModalP>{data[23].habitat}</ModalP>
                <a href={data[23].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '27' &&
            <div className='area_modal'>
                <h2>{data[27].botanic_name}, {data[27].maori_name}</h2>
                <ModalImages src={data[27].image_url} alt='plant images' />
                <ModalP>{data[27].habitat}</ModalP>
                <a href={data[27].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '18' &&
            <div className='area_modal'>
                <h2>{data[18].botanic_name}, {data[18].maori_name}</h2>
                <ModalImages src={data[18].image_url} alt='plant images' />
                <ModalP>{data[18].habitat}</ModalP>
                <a href={data[18].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '50' &&
            <div className='area_modal'>
                <h2>{data[50].botanic_name}, {data[50].maori_name}</h2>
                <ModalImages src={data[50].image_url} alt='plant images' />
                <ModalP>{data[50].habitat}</ModalP>
                <a href={data[50].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '1' &&
            <div className='area_modal'>
                <h2>{data[1].botanic_name}, {data[1].maori_name}</h2>
                <ModalImages src={data[1].image_url} alt='plant images' />
                <ModalP>{data[1].habitat}</ModalP>
                <a href={data[1].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        <div>
            <Heading>Section 11</Heading> 
            <p>Project Gold and Ngaio patch up to track.</p>
            <h2>Planting Plan</h2>
            <p>
                <button className='button' type='button' onClick={() => setPlant('34')}>
                Flax
                </button>
                and scattered
                <button className='button' type='button' onClick={() => setPlant('47')}>
                Ngaio,
                </button>
                <button className='button' type='button' onClick={() => setPlant('49')}>
                Pittosporum tenuifolium,
                </button>
                <button className='button' type='button' onClick={() => setPlant('21')}>
                Mahoe,
                </button>
                could provide some shelter.
                </p>
                <p>
                Among the kowhai -
                <button className='button' type='button' onClick={() => setPlant('25')}>
                Veronica elliptica,
                </button>
                <button className='button' type='button' onClick={() => setPlant('29')}>
                Coprosma propinqua,
                </button>
                <button className='button' type='button' onClick={() => setPlant('48')}>
                Coprosma rigida,
                </button>
                coprosma crassifolia [?],
                <button className='button' type='button' onClick={() => setPlant('23')}>
                Olearia avicenniifolia.
                </button>
                </p>
                <p>
                Cabbage trees
                <button className='button' type='button' onClick={() => setPlant('31')}>
                </button>
                can be planted in sheltered spaces (not to be prioritised over shrubs). Near rocks
                <button className='button' type='button' onClick={() => setPlant('27')}>
                Helicrysimum,
                </button>
                <button className='button' type='button' onClick={() => setPlant('40')}>
                Muehlenbeckia,
                </button>
                or grasses such as
                <button className='button' type='button' onClick={() => setPlant('18')}>
                Carex appressa,
                </button>
                <button className='button' type='button' onClick={() => setPlant('50')}>
                Carex flagellifera,
                </button>
                <button className='button' type='button' onClick={() => setPlant('19')}>
                Poa cita.
                </button>
            </p>
        </div>
    </div>
    )
}

export default Eleven
