import React, { useState } from 'react'
import styled from 'styled-components'
// import AllArea from './Components/AllArea.js'
import SectionMap from './Components/Layers/SectionMap.js'
import PlantList from './Components/PlantList.js'
import Island from './assets/images/BasicMap.svg'
import MarkerMap from './Components/MarkerMap.js'
import './App.css'

const Heading = styled.h1 `
    cursor: pointer;
`
const NavTab = styled.button `
    color: #ffffff;
`
const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
`

const App = () => {
  const [ type, setType ] = useState('')
    return (
        <div className='wrapper'>
            <div className='sticky_header'>
                <div className='heading'>
                    <Heading onClick={()=> setType('')}>QIKT App</Heading>
                </div>
                <div className='grid'>
                    <NavTab onClick={()=>setType('showPlan')}>Planting Plan 2021</NavTab>
                    <NavTab onClick={()=>setType('showList')}>Plant List</NavTab>
                    <NavTab onClick={()=>setType('showMarkers')}>Biodiversity</NavTab>
                </div>
            </div>
            <Main>
                    { type === '' &&
                        <div className='home_layout'>
                            <div className='home_image'>
                                <img src={Island} alt='island' />
                            </div>
                            <div className='home_intro'>
                                <h2>This is a prototype web app for community members and volunteers on Quarantine Island/Kamau Taurua.
                                </h2>
                                <p>This app aims to transport information about current projects into an easily accessible platform.</p>
                                <ul>
                                    <li><b>Planting Plan 2021</b> - areas highlighted for focus in current planting season.</li>
                                </ul>
                                <ul>
                                    <li>A <b>plant list</b> of endemic flora used in the forest restoration project</li>
                                </ul>
                                <ul>
                                    <li><b>Biodiversity</b> relating to the bird survey, skink survey and pest control projects</li>
                                </ul>
                                <p>The app was developed as a personal project, and it is based on documentation that in some places requires updating. If this app could be of use to you and you see an area of improvement, please let Meghan know!</p>
                            </div>
                        </div>
                    }
                  { type === 'showPlan' && <SectionMap /> }
                  { type === 'showList' && <PlantList /> }
                  { type === 'showMarkers' && <MarkerMap /> }
              </Main>
        </div>
    )
}

export default App
