import React, { useState } from 'react'
import styled from 'styled-components'
import '../../assets/styles/component.css'
import { data } from '../../data.js'

const Heading = styled.h1 `
    margin-top: 0;
`

const ModalImages = styled.img `
    max-width: 200px;
`

const ModalP = styled.p `
    text-align: center;
`

const ThreeA = () => {
    const [ plant, setPlant ] = useState()
    return (
        <div>
        { plant === '47' &&
            <div className='area_modal'>
                <h2>{data[47].botanic_name}, {data[47].maori_name}</h2>
                <ModalImages src={data[47].image_url} alt='plant images' />
                <ModalP>{data[47].habitat}</ModalP>
                <a href={data[47].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '21' &&
            <div className='area_modal'>
                <h2>{data[21].botanic_name}, {data[21].maori_name}</h2>
                <ModalImages src={data[21].image_url} alt='plant images' />
                <ModalP>{data[21].habitat}</ModalP>
                <a href={data[21].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '19' &&
            <div className='area_modal'>
                <h2>{data[19].botanic_name}, {data[19].maori_name}</h2>
                <ModalImages src={data[19].image_url} alt='plant images' />
                <ModalP>{data[19].habitat}</ModalP>
                <a href={data[19].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '37' &&
            <div className='area_modal'>
                <h2>{data[37].botanic_name}, {data[37].maori_name}</h2>
                <ModalImages src={data[37].image_url} alt='plant images' />
                <ModalP>{data[37].habitat}</ModalP>
                <a href={data[37].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === '4' &&
            <div className='area_modal'>
                <h2>{data[4].botanic_name}, {data[4].maori_name}</h2>
                <ModalImages src={data[4].image_url} alt='plant images' />
                <ModalP>{data[4].habitat}</ModalP>
                <a href={data[4].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === 'coprosma_spp' &&
            <div className='area_modal'>
                <h2>{data[36].botanic_name}, {data[36].maori_name}</h2>
                <ModalImages src={data[36].image_url} alt='plant images' />
                <ModalP>{data[36].habitat}</ModalP>
                <a href={data[36].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[29].botanic_name}, {data[29].maori_name}</h2>
                <ModalImages src={data[29].image_url} alt='plant images' />
                <ModalP>{data[29].habitat}</ModalP>
                <a href={data[29].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[36].botanic_name}, {data[36].maori_name}</h2>
                <ModalImages src={data[36].image_url} alt='plant images' />
                <ModalP>{data[36].habitat}</ModalP>
                <a href={data[36].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        { plant === 'carex_spp' &&
            <div className='area_modal'>
                <h2>{data[50].botanic_name}, {data[50].maori_name}</h2>
                <ModalImages src={data[50].image_url} alt='plant images' />
                <ModalP>{data[50].habitat}</ModalP>
                <a href={data[50].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[17].botanic_name}, {data[17].maori_name}</h2>
                <ModalImages src={data[17].image_url} alt='plant images' />
                <ModalP>{data[17].habitat}</ModalP>
                <a href={data[17].url} target='blank'>Click here to NZPCN page</a>
                <hr></hr>
                <h2>{data[18].botanic_name}, {data[18].maori_name}</h2>
                <ModalImages src={data[18].image_url} alt='plant images' />
                <ModalP>{data[18].habitat}</ModalP>
                <a href={data[18].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
                <hr></hr>
                <h2>{data[20].botanic_name}, {data[20].maori_name}</h2>
                <ModalImages src={data[20].image_url} alt='plant images' />
                <ModalP>{data[20].habitat}</ModalP>
                <a href={data[20].url} target='blank'>Click here to NZPCN page</a>
                <button class="close-button" onClick={() => setPlant()}>X</button>
            </div>
        }
        <div>
            <Heading>Section 29</Heading> 
            <p>Slope above big bay north - section protected by electric fence. Very steep.</p>
            <h2>Planting Plan</h2>
            <p>
                <button className='button' type='button' onClick={() => setPlant('47')}>
                    Ngaio,
                </button>
                <button className='button' type='button' onClick={() => setPlant('coprosma_spp')}>
                    Coprosma spp,
                </button>
                <button className='button' type='button' onClick={() => setPlant('21')}>
                Mahoe,
                </button>
                and
                <button className='button' type='button' onClick={() => setPlant('37')}>
                Corokia
                </button>
                working from the bottom up and bushline on side, planting in clumps, possibly best to plant grasses such as
                <button className='button' type='button' onClick={() => setPlant('carex_spp')}>
                    carex spp
                </button>
                and
                <button className='button' type='button' onClick={() => setPlant('19')}>
                    poa.
                </button>
                </p>
                <p>
                Addition of
                <button className='button' type='button' onClick={() => setPlant('4')}>
                Fuchsia
                </button>
                in sheltered damper spots and further diversity can be added with time. Isolated
                <button className='button' type='button' onClick={() => setPlant('coprosma_spp')}>
                Coprosma
                </button>
                that are completely protected from the sheep around the top.
            </p>
        </div>
    </div>
    )
}

export default ThreeA
